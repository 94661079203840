@import "./assets/scss/variable.scss";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "ckeditor5/ckeditor5.css";

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-mdc-form-field {
	min-width: 100%;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
	background-color: white !important;
}

.table-container {
	height: 66vh;
	overflow: auto;
}

mat-paginator {
	position: sticky;
	bottom: 0;
	background: white; /* Ensure the paginator is visible over the table content */
	z-index: 1;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent;
	background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	background-repeat: no-repeat;
	background-position-x: 100%;
	background-position-y: 5px;
	border: 1px solid #dfdfdf;
	border-radius: 2px;
	margin-right: 2rem;
	padding: 1rem;
	padding-right: 2rem;
}

/* .custom-select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%; 
}

.custom-select {
    width: 100%;
    appearance: none; 
    padding: 10px; 
    padding-right: 30px; 
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    line-height: 1.5;
}

.custom-select-wrapper::after {
    content: '\2193'; 
    position: absolute;
    top: 65%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none; 
    font-size: 20px;
    color: black;
} */

.mat-mdc-paginator-container {
	padding-bottom: 10px !important;
}

.text-completed {
	color: rgb(0, 123, 255);
}

.text-active {
	color: rgb(40, 167, 69);
}

.text-reschedule {
	color: rgb(255, 152, 0);
}
.text-cancel {
	color: rgb(220, 53, 69);
}
.text-freeze {
	color: rgb(0, 123, 255);
}

.ck-editor__editable_inline {
	/* min-height: 20vh; */
	min-width: 60vw;
	color: black;
}
.ck.ck-editor {
	width: 80vw;
}
