
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import './assets/scss/variable.scss';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// $secondary: 

$Papaya-Frontend-primary: mat.define-palette(mat.$amber-palette, 700);
$Papaya-Frontend-accent: mat.define-palette(mat.$cyan-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Papaya-Frontend-warn: mat.define-palette(mat.$red-palette);
// $custom-color: mat.define-palette(mat.$amber-palette,700);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Papaya-Frontend-theme: mat.define-light-theme((
  color: (
    primary: $Papaya-Frontend-primary,
    accent: $Papaya-Frontend-accent,
    warn: $Papaya-Frontend-warn,
    // btnColor:$custom-color
  ),
  typography: mat.define-typography-config(),
  density: 0
));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Papaya-Frontend-theme);

.loginBtn{
 color: white !important;
}
.InputFieldLogin{
  background-color: white !important;
}

::ng-deep .mat-drawer-content {
  overflow-y: hidden !important;
}


  .error-snackbar .mdc-snackbar__surface{
      background-color: rgb(183, 12, 12) !important;
  }

  .warning-snackbar .mdc-snackbar__surface{
    background-color: #FCB002 !important;
}

.success-snackbar .mdc-snackbar__surface{
  background-color: green !important;
}

/* For Chrome, Safari, and Opera */
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track mat-sidenav {
  background-color: var(--primary); /* Color of the track */
}

::-webkit-scrollbar-thumb {
  // background-color: #aaaaaa; /* Color of the thumb */
  border-radius: 5px; /* Roundness of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  // background-color: #707070; /* Color of the thumb on hover */
}

mat-icon-button:hover {
  pointer-events: none;
}


.btnText{
  color: white !important;
}